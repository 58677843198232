.create-tenant {
  .card-wrapper {
    display: flex;
    justify-content: center;
    .form-wrapper {
      width: 50%;
      padding: 1rem 0rem;
      input[type='file'] {
        display: none;
      }
    }
  }
  @media (max-width: 800px) {
    .form-div {
      width: 80%;
    }
  }

  .prop-label {
    text-align: left;
    font-size: 16px;

    color: var(--clr-text-100);
    margin-bottom: 0.5rem !important;
  }
}

.tenant-details {
  color: #acacac;

  p {
    font-weight: 600;
    color: #acacac;
    margin-bottom: 0.5rem;
  }
  .values {
    font-size: 14px;
    color: #acacac;
    margin-left: 3px;
    overflow-wrap: break-word;
  }

  .border-right {
    border-right: 1px solid white;
  }

  .actions {
    cursor: pointer;
    text-decoration: underline;
  }
}
