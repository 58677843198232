:root {
  /* Constant colors */
  --clr-success: #059100;
  --clr-warning: #ef8700;
  --clr-danger: #d32f2f;
  --clr-disable: #9d9d9d;

  --clr-primary-300: #81bbff;
  --clr-primary-500: #0066ff;

  --clr-shadow: #00000029;

  /* End constant colors */

  /* Data */
  --data-logo: url('../assets/logo/datapelago-logo-black.png');
  --data-logo-without-name: url('../assets/logo/datapelago-logo.png');

  --data-auth-bg: url('../assets/images/auth-background.jpg');

  --clr-filter-menu-btn: invert(70%) brightness(100%);

  /* Text */
  --clr-text-100: #121212;
  --clr-text-300: #2a2a2a;
  --clr-text-500: #545454;

  /* Background */
  --clr-background-50: #ffffff;
  --clr-background-100: #f8fbff;
  --clr-background-200: #ebebeb;
  --clr-background-300: #e0e0e0;

  /* Card */
  --clr-card-100: #ffffff;
  --clr-card-200: #e8e8e8;
  --clr-card-300: #bbbbbb;

  /* Border */
  --clr-border-50: #e8e8e8;
  --clr-border-100: #bbbbbb;
  --clr-border-200: #777777;
  --clr-border-500: #595959;
  --clr-border-800: #333333;

  /* Table */
  --clr-table-background: var(--clr-card-100);
  --clr-table-background-even: var(--clr-card-200);
  --clr-table-text-head: var(--clr-text-100);
  --clr-table-text: var(--clr-text-300);
  --clr-table-text-even: var(--clr-text-300);
  --clr-table-text-id: var(--clr-primary-500);
  --clr-table-border: var(--clr-border-50);

  /* Other */

  --clr-details-label: #3c3b3b;

  --clr-crumbs-text: #3b3b3b;

  --clr-disabled-btn: #b3b2b2;
  --clr-notification: #717070;
  --clr-notification-selected: #000000;

  /* Input */
  --clr-input-background: #ffffff;
  --clr-input-background-disable: #e8e8e8;
  --clr-input-text: var(--clr-text-300);
  --clr-input-placeholder: #2a2a2a;
  --clr-input-border: #595959;
  --clr-input-border-focus: var(--clr-primary-500);

  /* Header */
  --clr-header-background: #ffffff;
  --clr-header-text: #2a2a2a;
  --clr-header-menu-background: #ffffff;
  --clr-header-menu-text: #2a2a2a;
  --clr-header-border: #e8e8e8;

  /* Sidebar */
  --clr-sidebar-background: #ffffff;
  --clr-sidebar-tile: var(--clr-primary-500);
  --clr-sidebar-tile-text: #2a2a2a;
  --clr-sidebar-tile-text-active: #ffffff;
  --clr-sidebar-tile-submenu-text-active: #ffffff;

  /* Body */
  --clr-body-background: #e8e8e8;
  --clr-body-text: #202020;

  /* Footer */
  --clr-footer-background: var(--clr-card-100);
  --clr-footer-text: var(--clr-text-300);
  --clr-footer-text-active: var(--clr-primary-500);
  --clr-footer-border: var(--clr-card-100);

  /* Buttons */
  --clr-primary-btn-background: var(--clr-primary-500);
  --clr-primary-btn-background-hover: #ffffff;
  --clr-primary-btn-text: #ffffff;
  --clr-primary-btn-text-hover: var(--clr-primary-500);
  --clr-primary-btn-border: var(--clr-primary-500);
  --clr-primary-btn-border-hover: var(--clr-primary-500);

  --clr-secondary-btn-background: #ffffff;
  --clr-secondary-btn-background-hover: var(--clr-primary-500);
  --clr-secondary-btn-text: var(--clr-primary-500);
  --clr-secondary-btn-text-hover: #ffffff;
  --clr-secondary-btn-border: var(--clr-primary-500);
  --clr-secondary-btn-border-hover: var(--clr-primary-500);

  --clr-outline-btn-background: transparent;
  --clr-outline-btn-background-hover: var(--clr-primary-500);
  --clr-outline-btn-text: var(--clr-text-100);
  --clr-outline-btn-text-hover: #ffffff;
  --clr-outline-btn-border: var(--clr-border-500);
  --clr-outline-btn-border-hover: var(--clr-border-500);

  --clr-disable-btn-background: var(--clr-card-200);
  --clr-disable-btn-text: var(--clr-text-300);
  --clr-disable-btn-border: var(--clr-border-500);

  --clr-rounded-btn: var(--clr-primary-500);
  --clr-rounded-btn-disable: var(--clr-disable-btn-text);

  /* Code */
  --clr-code-background: #fafafa;

  /* Graph */
  --clr-graph-background: var(--clr-card-100);
  --clr-graph-node-head-background: var(--clr-card-300);
  --clr-graph-node-head-text: var(--clr-text-300);
  --clr-graph-node-body-background: var(--clr-card-200);
  --clr-graph-node-body-text: var(--clr-text-300);
}

:root .theme--light {
  --data-logo: url('../assets/logo/datapelago-logo-black.png');
  --clr-filter-menu-btn: invert(70%) brightness(100%);

  --clr-primary-300: #5d9eff;
  --clr-primary-500: #2a6bcc;

  /* Text */
  --clr-text-100: #121212;
  --clr-text-300: #2a2a2a;
  --clr-text-500: #545454;

  /* Background */
  --clr-background-50: #ffffff;
  --clr-background-100: #f8fbff;
  --clr-background-200: #ebebeb;
  --clr-background-300: #e0e0e0;

  /* Card */
  --clr-card-100: #ffffff;
  --clr-card-200: #e8e8e8;
  --clr-card-300: #bbbbbb;

  /* Border */
  --clr-border-50: #e8e8e8;
  --clr-border-100: #bbbbbb;
  --clr-border-200: #777777;
  --clr-border-500: #595959;
  --clr-border-800: #333333;

  /* Table */
  --clr-table-background: var(--clr-card-100);
  --clr-table-background-even: var(--clr-card-200);
  --clr-table-text-head: var(--clr-text-100);
  --clr-table-text: var(--clr-text-300);
  --clr-table-text-even: var(--clr-text-300);
  --clr-table-text-id: var(--clr-primary-500);
  --clr-table-border: var(--clr-border-50);

  /* Other */

  --clr-details-label: #3c3b3b;

  --clr-crumbs-text: #3b3b3b;

  --clr-disabled-btn: #b3b2b2;
  --clr-notification: #717070;
  --clr-notification-selected: #000000;

  /* Input */
  --clr-input-background: #ffffff;
  --clr-input-background-disable: #e8e8e8;
  --clr-input-text: var(--clr-text-300);
  --clr-input-placeholder: #2a2a2a;
  --clr-input-border: #595959;
  --clr-input-border-focus: var(--clr-primary-500);

  /* Header */
  --clr-header-background: #ffffff;
  --clr-header-text: #2a2a2a;
  --clr-header-menu-background: #ffffff;
  --clr-header-menu-text: #2a2a2a;
  --clr-header-border: #e8e8e8;

  /* Sidebar */
  --clr-sidebar-background: #ffffff;
  --clr-sidebar-tile: var(--clr-primary-500);
  --clr-sidebar-tile-text: #2a2a2a;
  --clr-sidebar-tile-text-active: #ffffff;
  --clr-sidebar-tile-submenu-text-active: #ffffff;

  /* Body */
  --clr-body-background: #e8e8e8;
  --clr-body-text: #202020;

  /* Footer */
  --clr-footer-background: var(--clr-card-100);
  --clr-footer-text: var(--clr-text-300);
  --clr-footer-text-active: var(--clr-primary-500);
  --clr-footer-border: var(--clr-card-100);

  /* Buttons */
  --clr-primary-btn-background: var(--clr-primary-500);
  --clr-primary-btn-background-hover: #ffffff;
  --clr-primary-btn-text: #ffffff;
  --clr-primary-btn-text-hover: var(--clr-primary-500);
  --clr-primary-btn-border: var(--clr-primary-500);
  --clr-primary-btn-border-hover: var(--clr-primary-500);

  --clr-secondary-btn-background: #ffffff;
  --clr-secondary-btn-background-hover: var(--clr-primary-500);
  --clr-secondary-btn-text: var(--clr-primary-500);
  --clr-secondary-btn-text-hover: #ffffff;
  --clr-secondary-btn-border: var(--clr-primary-500);
  --clr-secondary-btn-border-hover: var(--clr-primary-500);

  --clr-outline-btn-background: transparent;
  --clr-outline-btn-background-hover: var(--clr-primary-500);
  --clr-outline-btn-text: var(--clr-text-100);
  --clr-outline-btn-text-hover: #ffffff;
  --clr-outline-btn-border: var(--clr-border-500);
  --clr-outline-btn-border-hover: var(--clr-border-500);

  --clr-disable-btn-background: var(--clr-card-200);
  --clr-disable-btn-text: var(--clr-text-300);
  --clr-disable-btn-border: var(--clr-border-500);

  --clr-rounded-btn: var(--clr-primary-500);
  --clr-rounded-btn-disable: var(--clr-disable-btn-text);

  /* Code */
  --clr-code-background: #fafafa;

  /* Graph */
  --clr-graph-background: var(--clr-card-100);
  --clr-graph-node-head-background: var(--clr-card-300);
  --clr-graph-node-head-text: var(--clr-text-300);
  --clr-graph-node-body-background: var(--clr-card-200);
  --clr-graph-node-body-text: var(--clr-text-300);
}

:root .theme--dark {
  --data-logo: url('../assets/logo/datapelago-logo-white.png');
  --clr-filter-menu-btn: invert(0%) brightness(100%);

  --clr-primary-300: #66a3ff;
  --clr-primary-500: #0066ff;

  /* Text */
  --clr-text-100: #ffffff;
  --clr-text-300: #e8e8e8;
  --clr-text-500: #acacac;

  /* Background */
  --clr-background-50: #121212;
  --clr-background-100: #262626;
  --clr-background-200: #2e2e2e;
  --clr-background-300: #363636;

  /* Card */
  --clr-card-100: #2a2a2a;
  --clr-card-200: #363636;
  --clr-card-300: #303030;

  /* Border */
  --clr-border-50: #333333;
  --clr-border-100: #595959;
  --clr-border-200: #777777;
  --clr-border-500: #bbbbbb;
  --clr-border-800: #e8e8e8;

  /* Table */
  --clr-table-background: var(--clr-card-100);
  --clr-table-background-even: var(--clr-card-200);
  --clr-table-text-head: var(--clr-text-100);
  --clr-table-text: var(--clr-text-300);
  --clr-table-text-even: var(--clr-text-300);
  --clr-table-text-id: var(--clr-primary-500);
  --clr-table-border: var(--clr-border-50);

  /* Other */
  --clr-details-label: #acacac;

  --clr-crumbs-text: #818181;

  --clr-disabled-btn: #f1eded;
  --clr-notification: #a7a7a7;
  --clr-notification-selected: #edeaea;

  /* Input */
  --clr-input-background: var(--clr-card-100);
  --clr-input-background-disable: var(--clr-card-200);
  --clr-input-text: var(--clr-text-300);
  --clr-input-placeholder: var(--clr-text-500);
  --clr-input-border: var(--clr-border-200);
  --clr-input-border-focus: var(--clr-primary-500);

  /* Header */
  --clr-header-background: #2a2a2a;
  --clr-header-text: #f1f1f1;
  --clr-header-menu-background: #3b3b3b;
  --clr-header-menu-text: #eeeeee;
  --clr-header-border: #262626;

  /* Sidebar */
  --clr-sidebar-background: #2a2a2a;
  --clr-sidebar-tile: var(--clr-primary-500);
  --clr-sidebar-tile-text: #e8e8e8;
  --clr-sidebar-tile-text-active: #ffffff;
  --clr-sidebar-tile-submenu-text-active: #ffffff;

  /* Body */
  --clr-body-background: #121212;
  --clr-body-text: #202020;

  /* Footer */
  --clr-footer-background: var(--clr-card-100);
  --clr-footer-text: var(--clr-text-300);
  --clr-footer-text-active: var(--clr-primary-500);
  --clr-footer-border: var(--clr-card-100);

  /* Buttons */
  --clr-primary-btn-background: var(--clr-primary-500);
  --clr-primary-btn-background-hover: #ffffff;
  --clr-primary-btn-text: #ffffff;
  --clr-primary-btn-text-hover: var(--clr-primary-500);
  --clr-primary-btn-border: var(--clr-primary-500);
  --clr-primary-btn-border-hover: var(--clr-primary-500);

  --clr-secondary-btn-background: #ffffff;
  --clr-secondary-btn-background-hover: var(--clr-primary-500);
  --clr-secondary-btn-text: var(--clr-primary-500);
  --clr-secondary-btn-text-hover: #ffffff;
  --clr-secondary-btn-border: var(--clr-primary-500);
  --clr-secondary-btn-border-hover: var(--clr-primary-500);

  --clr-outline-btn-background: transparent;
  --clr-outline-btn-background-hover: var(--clr-primary-500);
  --clr-outline-btn-text: var(--clr-text-100);
  --clr-outline-btn-text-hover: #ffffff;
  --clr-outline-btn-border: var(--clr-border-500);
  --clr-outline-btn-border-hover: var(--clr-border-500);

  --clr-disable-btn-background: var(--clr-card-300);
  --clr-disable-btn-text: var(--clr-text-300);
  --clr-disable-btn-border: var(--clr-border-200);

  --clr-rounded-btn: var(--clr-primary-300);
  --clr-rounded-btn-disable: var(--clr-disable-btn-text);

  /* Code */
  --clr-code-background: #282c34;

  /* Graph */
  --clr-graph-background: var(--clr-card-100);
  --clr-graph-node-head-background: var(--clr-card-300);
  --clr-graph-node-head-text: var(--clr-text-300);
  --clr-graph-node-body-background: var(--clr-card-200);
  --clr-graph-node-body-text: var(--clr-text-300);
}
